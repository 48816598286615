/*----------------------------------------------Home Section--------------------------------------------------*/

.homeContainer{
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}

.bannerSection{
    background-color: #283148;
    height: min-content;
    height: auto;
    display: flex;
    gap: 5rem;
    margin-bottom: 3rem;
    margin-top: 5rem;
}
.imageContainer{
    flex-basis: 30%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: slideFromLeft;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    transform: translateX(-30rem);
}
@keyframes slideFromLeft {
    from{
        transform: translateX(-30rem);
    }
    to{
        transform: translateX(0);
    }
}
.bannerImage{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.contentContainer{
    flex-basis: 60%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    animation-name: slideFromRight;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    transform: translateX(55rem);
}
@keyframes slideFromRight {
    from{
        transform: translateX(55rem);
    }
    to{
        transform: translateX(0);
    }
}
.homeHeading{
    font-weight: 900;
    -webkit-text-stroke: 0.1vw #FF9B42;
    font-size: 3rem;
    margin: 0;
    color: #283148;
    line-height: 1.2;
    word-spacing: 4px;
}
.homeSubHeading{
    font-weight: 600;
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.9rem;
    color: white;
}
.homeText{
    color: white;
    width: 95%;
    text-align: justify;
    font-size: 1.35rem;
    margin-bottom: 1.5rem;
}
.homeBtn{
    background-color: #283148;
    padding: 1rem 2rem;
    border: 0.2rem solid #FF9B42;
    border-radius: 2rem;
    font-size: 1.15rem;
    font-weight: 600;
    color: white;
    transition: all 1.5s;
}
.homeBtn:hover{
    background-color: #FF9B42;
    border: none;
    color: black;
    cursor: pointer;
}

@media (max-width:1115px) {
    .homeHeading{
        font-size: 2.3rem;
    }
    .homeSubHeading{
        font-size: 1.4rem;
    }
    .homeText{
        font-size: 1.1rem;
    }
    .homeBtn{
        font-size: 1rem;
    }
}

@media (max-width:1000px) {
    .bannerSection{
        gap: 2rem;
    }
    .contentContainer{
        flex-basis: 65%;
    }
    .homeHeading{
        font-size: 2rem;
    }
    .homeSubHeading{
        font-size: 1.2rem;
    }
    .homeText{
        font-size: 0.95rem;
    }
    .homeBtn{
        font-size: 0.85rem;
    }
}

@media (max-width:800px) {
    .bannerImage{
        object-fit: cover;
    }
}

@media (max-width:890px) {
    .homeHeading{
        font-size: 1.8rem;
    }
    .homeSubHeading{
        font-size: 1.1rem;
    }
    .homeText{
        font-size: 0.9rem;
    }
    .homeBtn{
        font-size: 0.8rem;
    }
}

@media (max-width:803px) {
    .homeHeading{
        font-size: 1.6rem;
    }
    .homeBtn{
        padding: 0.7rem 1rem;
    }
}

@media (max-width:599px) {
    .bannerSection{
        flex-direction: column;
        height: auto;
    }
    .bannerImage{
        height: 25vh;
    }
}
