.whatWeDoContainer{
    display: flex;
    align-items: center;
    background-color: #283148;
    padding: 2rem;
    height: auto;
}
.whatWeDoImageContainer{
    flex-basis: 40%;
}
.whatWeDoImageContainer > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.whatWeDoContentContainer{
    flex-basis: 60%;
}
.whatWeDoContentContainer > h2{
    font-size: 2.5rem;
    color: white;
}
.whatWeDoContentContainer .whatWeDoText{
    font-size: 1.35rem;
    color: white;
}

@media (max-width:1115px) {
    .whatWeDoContentContainer > h2{
        font-size: 2.3rem;
    }
    .whatWeDoContentContainer .whatWeDoText{
        font-size: 1.1rem;
    }
}

@media (max-width:1000px) {
    .whatWeDoContentContainer > h2{
        font-size: 2rem;
    }
    .whatWeDoContentContainer .whatWeDoText{
        font-size: 0.95rem;
    }
}

@media (max-width:890px) {
    .whatWeDoContentContainer > h2{
        font-size: 1.8rem;
    }
    .whatWeDoContentContainer .whatWeDoText{
        font-size: 0.9rem;
    }
}

@media (max-width:803px) {
    .whatWeDoContentContainer > h2{
        font-size: 1.6rem;
    }
}

@media (max-width:720px) {
    .whatWeDoImageContainer{
        flex-basis: 49%;
        margin-right: 2rem;
    }
    .whatWeDoImageContainer > img{
        height: 70vh;
    }
    .whatWeDoContentContainer{
        flex-basis: 49%;
    }
}

@media (max-width:600px) {
    .whatWeDoContainer{
        flex-direction: column;
    }
    .whatWeDoImageContainer > img{
        height: 100%;
    }
}

