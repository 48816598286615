.contactSection{
    background-color: #fff;
    /* height: 90vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    margin-top: 3rem;
}
.contactContent{
    flex-basis: 60%;
    padding: 2rem;
}
.contactContent h2{
    font-weight: 600;
    margin: 0;
    font-size: 1.5rem;
    color: #283148;
}
.contactContent h1{
    font-weight: 900;
    font-size: 2.6rem;
    margin: 0;
    line-height: 1.2;
    color: #283148;
}
.contactContent .timing{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: -2rem;
}
.contactContent .timing h3{
    font-weight: 600;
    font-size: 1.2rem;
    color: #283148;
}
.contactContent .timing .clockIcon{
    font-size: 2rem;
    color: #283148;
}
.contactContent p{
    color: #283148;
    width: 100%;
    text-align: justify;
    font-size: 1.35rem;
    margin-bottom: 1.5rem;
}
.contactNumberContainer{
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 100%;
}
.iconContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    flex-basis: 10%;
}
.iconContainer > div{
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    border-radius: 1rem;
    background-color: #283148;
    display: flex;
    align-items: center;
    justify-content: center;
}
.iconContainer> div svg{
    font-size: 3rem;
}
.contactNameAndNo{
    display: flex;
    margin-left: -2rem;
    align-items: center;
    width: 140%;
    justify-content: space-between;
}
.contactNumber h3{
    font-size: 1.4rem;
    color: #283148;
    font-weight: 900;
}
.directorName{
    margin-left: 1rem;
}
.consultantNo{
    margin-left: 1.3rem;
}
.phoneIcon{
    color: white;
}

.contactFormContainer{
    flex-basis: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contactFrom{
    background-color: #283148;
    height: auto;
    width: 93%;
    border-radius: 2rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transform: translateX(200px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}
.contactFrom.visible{
    opacity: 1;
    transform: translateX(0);
}
.contactFrom h2{
    text-align: center;
    font-weight: 600;
    margin: 0;
    font-size: 1.9rem;
    line-height: 1.2;
    font-family: "Poppins", sans-serif;
    color: white;
}
.contactForm{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}
.contactFrom .username,
.contactFrom .email,
.contactFrom .phoneno{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.contactFrom .message{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}
.contactFrom input{
    width: 30vw;
    padding: 0.8rem;
    border-radius: 0.5rem;
    border: none;
    font-size: 1.35rem;
    font-weight: 900;
    padding-left: 3rem;
    margin-left: 0.8rem;
}
.contactFrom textarea{
    width: 30vw;
    font-weight: 900;
    font-size: 1.35rem;
    padding: 0.8rem;
    padding-left: 3rem;
    background-color: white;
    margin-left: 0.8rem;
}
.contactForm svg{
    margin-right: -3.5rem;
    z-index: 10;
}
.contactForm .message svg{
    margin-top: 1rem;
}
.contactBtn{
    background-color: #FF9B42;
    padding: 1rem;
    border: none;
    color: #283148;
    font-size: 1.35rem;
    font-weight: 900;
    transition: 0.5s;
}
.contactBtn:hover{
    background-color: transparent;
    border: 0.2rem solid #FF9B42;
    color: white;
    cursor: pointer;
}

@media (max-width:1115px) {
    .contactContent h1{
        font-size: 2.3rem;
    }
    .contactContent h2{
        font-size: 1.4rem;
    }
    .contactContent .timing h3{
        font-size: 1.1rem;
    }
    .contactContent p,
    .contactFrom input,
    .contactFrom textarea,
    .contactBtn{
        font-size: 1.1rem;
    }
    .contactNumber h3{
        font-size: 1.3rem;
    }
    .contactFrom h2{
        font-size: 1.8rem;
    }
}

@media (max-width:1030px) {
    .contactContent h1{
        font-size: 2rem;
    }
    .contactContent h2{
        font-size: 1.2rem;
    }
    .contactFrom{
        transform: translateX(100px);
    }
    .contactContent p,
    .contactFrom input,
    .contactFrom textarea,
    .contactBtn{
        font-size: 0.95rem;
    }
    .contactNumber h3{
        font-size: 1.1rem;
    }
    .contactFrom h2{
        font-size: 1.3rem;
    }
}

@media (max-width:890px) {
    .contactContent h1{
        font-size: 1.8rem;
    }
    .contactContent h2{
        font-size: 1.1rem;
    }
    .contactContent .timing h3{
        font-size: 0.95rem;
    }
    .iconContainer{
        gap: 1rem;
    }
    .contactNameAndNo{
        width: 130%;
    }
    .contactContent p,
    .contactFrom input,
    .contactFrom textarea,
    .contactBtn{
        font-size: 0.9rem;
    }
    .contactContent{
        flex-basis: 55%;
    }
    .contactFormContainer{
        flex-basis: 45%;
    }
    .contactNumber h3{
        font-size: 0.9rem;
    }
    .contactFrom h2{
        font-size: 1.1rem;
    }
}

@media (max-width:768px) {
    .contactSection{
        flex-direction: column;
    }
    .contactNameAndNo{
        width: 70vw;
    }
    .contactContent{
        width: 95%;
    }
    .directorName{
        margin-left: 0.5rem;
    }
    .consultantNo{
        margin-left: 1.1rem;
    }
}

@media (max-width:700px) {
    .iconContainer{
        display: none;
    }
    .contactNameAndNo{
        width: 75vw;
        margin: auto;
    }
    .contactForm input,
    .contactForm textarea{
        width: 100%;
    }
}
