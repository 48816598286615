.fAQContent .heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #283148;
    margin-bottom: 1rem;
    padding: 0.5rem;
    opacity: 0;
    transform: translateX(-200px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}
.fAQContent .heading.visible{
    opacity: 1;
    transform: translateX(0);
}
.fAQContent .heading h3{
    color: white;
    font-size: 1.35rem;
}
.fAQContent .heading svg{
    color: #FF9B42;
    font-size: 2.5rem;
}
.fAQContent .heading svg:hover{
    cursor: pointer;
    scale: 1.5;
}
.fAQContent p{
    background-color: #FF9B42;
    padding: 1rem;
    font-weight: 600;
    margin-top: -1rem;
    color: #283148;
    display: none;
    font-size: 1.35rem;
} 


@media (max-width:1115px) {
    .fAQContent .heading h3,
    .fAQContent p{
        font-size: 1.1rem;
    }
}

@media (max-width:1000px) {
    .fAQContent .heading h3,
    .fAQContent p{
        font-size: 0.95rem;
    }
}

@media (max-width:555px) {
    .fAQContent .heading{
        transform: translateX(-100px);
    }
}

@media (max-width:890px) {
    .fAQContent .heading h3,
    .fAQContent p{
        font-size: 0.9rem;
    }
}
