a{
    text-decoration: none;
}
.headers{
    width: 100vw;
    max-width: 100%;
    position: fixed;
    z-index: 1000;
    top: -0.5rem;
}
.headerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-top: 2.5rem solid #283148;
    border-bottom: 0.1rem solid #283148;
    padding: 0 3rem;
    height: 13vh;
    z-index: 10;
}
.logo{
    width: 5rem;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}
.headerImage{
    width: 6rem;
    margin-top: -1.5rem;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}
.headerlinks{
    display: flex;
    gap: 2rem;
    position: relative;
}
ul{
    list-style-type: none;
}
.list{
    display: inline-block;
    position: relative;
}
.headerlink{
    display: block;
    text-decoration: none;
    font-size: 1.2rem;
    color: #283148;
    font-weight: 600;
    transition: 0.5s;
    padding: 1rem;
}
.headerlink:hover{
    color: #FF9B42;
}
ul li ul.dropdown li{
    display: block;
}
ul li ul.dropdown1 li{
    display: block;
}
ul li ul.dropdown{
    width: 250%;
    /* background-color: #FF9B42; */
    background-color: #283148;
    border: 0.1rem solid #FF9B42;
    position: absolute;
    z-index: 999;
    display: none;
    padding: 0;
    margin-top: -0.5rem;
}
ul li ul.dropdown1{
    width: 180%;
    /* background-color: #FF9B42; */
    background-color: #283148;
    border: 0.1rem solid #FF9B42;
    position: absolute;
    z-index: 999;
    display: none;
    padding: 0;
    margin-top: -0.5rem;
}
ul li ul.dropdown a,
ul li ul.dropdown1 a{
    color: white;
}
ul li ul.dropdown li a:hover{
    background-color: #283148;
    border: 0.1rem solid #FF9B42;
    color: white;
}
ul li ul.dropdown1 li a:hover{
    background-color: #283148;
    border: 0.1rem solid #FF9B42;
    color: white;
}
ul li:hover ul.dropdown{
    display: block;
}
ul li:hover ul.dropdown1{
    display: block;
}

.smallScreenPanel{
    display: none;
}

.hamburger {
    background-color: #283148;
    width: 3rem;
    height: 3rem;
    border-radius: 0.7rem;
    position: fixed;
    top: 1rem;
    right: 1rem;
    border: 0.1rem solid skyblue;
    z-index: 1;
}

.hamburger span {
    width: 1.7rem;
    right: 1.6rem;
    top: 1.65rem;
    height: 0.25rem;
    background-color: white;
    position: fixed;
    z-index: 1;
}

.hamburger span:nth-child(2) {
    top: 2.45rem;
}

.hamburger span:nth-child(3) {
    top: 3.2rem;
}
.smallScreenHeader{
    display: flex;
    background-color: white;
    padding: 1rem;
}
.headerImageSmall{
    /* width: 8rem; */
    width: 5rem;
    position: relative;
    /* right: -7rem;
    top: -3rem; */
    /* top: 1rem; */
    /* left: 0.5rem; */
}
#sidePanel {
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: -100vw;
    background-color: white;
    transition: left 0.3s;
    z-index: 10;
    border-right: 0.2rem solid black;
}

.smallScreenLinks{
    display: flex;
    flex-direction: column;
    margin-left: 5rem;
}



 /* .smallScreenLinksContainer{
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 2rem !important;
    margin-top: -2rem !important;
}

.smallScreenLink{
    margin-top: -1rem !important;
    color: #283148;
    font-family: "Poppins", sans-serif;
    font-weight: 900;
}
.smallScreenLink2,
.smallScreenLink3{
    color: #283148;
    font-family: "Poppins", sans-serif;
    font-weight: 900;
}
.smallScreenSubLinksContainer1,
.smallScreenSubLinksContainer2{
    display: flex;
    gap: 3rem;
    margin-top: -2rem !important;
}
    .smallScreenSubLinksContainer1{
    margin-left: -1.3rem;
}
.smallScreenSubLinksContainer2{
    margin-left: -0.7rem;
}
.smallScreenSubLinksContainer{
    margin-left: -4rem;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: -2rem !important;
}
.small{
    margin-left: 2rem !important;
}
.smallScreenSubLink{
    margin-bottom: -2rem !important;
}

.smallScreenPanel a{
    font-size: 0.8rem;
}  */

#overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.567);
    display: none;
}

#close {
    padding: 0.5rem;
    background-color: black;
    position: relative;
}

#close svg {
    font-size: 1.5rem;
    color: white;
}

#sidePanel div {
    display: flex;
    margin-top: 3rem;
}

@media (max-width:1115px) {
    .headerlink{
        font-size: 1rem;
    }
}

@media (max-width:960px) {
    .headerlink{
        font-size: 0.9rem;
    }
}

@media (max-width:904px) {
    .headerlink{
        font-size: 0.85rem;
    }
}

@media (max-width:886px) {
    .headerImage{
        width: 5rem;
    }
    .headerlink{
        font-size: 0.8rem;
    }
}

@media (max-width:850px) {
    .headerImage{
        width: 4rem;
    }
    .headerlink{
        font-size: 0.75rem;
    }
}

/* @media (max-width:808px) {
    .headerImage{
        width: 3.5rem;
    }
    .headerlink{
        font-size: 0.75rem;
    }
} */

@media (max-width:823px) {
    .headerlink{
        font-size: 0.7rem;
    }
}

@media (max-width:797px) {
    .headerlink{
        font-size: 0.65rem;
    }
}

@media (max-width:780px) {
    .smallScreenPanel{
        display: block;
    }
    .headerContainer{
        display: none;
        border: 3rem solid #283148;
    }
    .headerlink{
        font-size: 0.75rem;
    }
}

@media (max-width:430px) {
    .smallScreenLinksContainer{
        margin-left: 3rem;
    }
}