.ourMissionContainer{
    display: flex;
    align-items: center;
    margin-top: 3rem;
    background-color: #283148;
    height: auto;
}
.missionImage{
    flex-basis: 40%;
}
.missionImage img{
    width: 30vw;
    height: 100%;
    object-fit: cover;
}
.missionContent{
    padding: 2rem;
}
.missionContent p{
    /* font-size: 1.1rem; */
    font-size: 1.35rem;
    color: white;
}



@media (max-width:1115px) {
    .missionContent p{
        font-size: 1.1rem;
    }
}

@media (max-width:1000px) {
    .missionContent p{
        font-size: 0.95rem;
    }
}

@media (max-width:890px) {
    .missionContent p{
        font-size: 0.9rem;
    }
}

@media (max-width:599px) {
    .ourMissionContainer{
        flex-direction: column;
    }
    .missionImage img{
        width: 100%;
        height: 30vh;
    }
}


/* @media (max-width:972px){
    .missionContent p {
        font-size: 0.8rem;
    }
    .missionImage{
        flex-basis: 30%;
    }
    .missionImage img{
        width: 20rem;
        height: 12rem;
    }
}

@media (max-width:700px){
    .missionImage img{
        width: 15rem;
        object-fit: cover;
    }
}

@media (max-width:522px){
    .missionImage img{
        width: 10rem;
    }
}

@media (max-width:425px){
    .ourMissionContainer{
        height: 50vh;
    }
    .missionImage img{
        height: 18rem;
        object-fit: cover;
    }
}

@media (max-width:415px){
    .ourMissionContainer{
        margin-top: 4rem;
       }
}

@media (max-width:385px){
    .ourMissionContainer{
        margin-top: 5rem;
    }
}

@media (max-width:373px){
    .missionImage img{
        width: 8rem;
        height: 18rem;
        object-fit: cover;
    }
}

@media (max-width:354px){
   .ourMissionContainer{
    margin-top: 7rem;
   }
}

@media (max-width:341px){
    .ourMissionContainer{
        height: 40vh;
    }
    .missionContent p{
        font-size: 0.7rem;
    }
    .missionImage img{
        width: 8rem;
        height: 11rem;
    }
 }

 @media (max-width:333px){
    .ourMissionContainer{
        margin-top: 3rem;
       }
 }

 @media (max-width:328px){
    .ourMissionContainer{
        flex-direction: column;
        height: 70vh;
        gap: 0;
    }
    .missionImage img{
        width: 20rem;
        height: 11rem;
    }
 }

 @media (max-width:260px){
    .ourMissionContainer{
        margin-top: 4rem;
       }
 }

 @media (max-width:253px){
    .ourMissionContainer{
        margin-top: 5rem;
       }
 }

 @media (max-width:240px){
    .ourMissionContainer{
        margin-top: 6rem;
       }
 } */
