.messageFromFounder{
    background-color: #283148;
    height: auto;
    display: flex;
    align-items: center;
    padding: 2rem;
    padding-left: 0;
    margin-top: 3rem;
}
.messageImageContainer{
    flex-basis: 28%;
    padding-left: 2rem;
}
img{
    width: 300px;
}
.aboutMessage{
    flex-basis: 70%;
    padding: 2rem;
}
.aboutMessage h1{
    font-weight: 900;
    font-size: 3rem;
    margin: 0;
    line-height: 1.2;
    font-family: "Poppins", sans-serif;
    color: #FF9B42;
}
.aboutMessage p{
    font-size: 1.35rem;
    color: white;
}

@media (max-width:1115px) {
    .aboutMessage h1{
        font-size: 2.3rem;
    }
    .aboutMessage p{
        font-size: 1.1rem;
    }
}

@media (max-width:1000px) {
    .aboutMessage h1{
        font-size: 2rem;
    }
    .aboutMessage p{
        font-size: 0.95rem;
    }
}

@media (max-width:890px) {
    .aboutMessage h1{
        font-size: 1.8rem;
    }
    .aboutMessage p{
        font-size: 0.9rem;
    }
}

@media (max-width:803px) {
    .aboutMessage h1{
        font-size: 1.6rem;
    }
}

@media (max-width:720px) {
    .messageFromFounder{
        flex-direction: column;
    }
}

