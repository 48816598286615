.aboutSection{
    /* height: 65.9vh; */
    height: auto;
    display: flex;
    background-color: #FF9B42;
}
.aboutContentContainer{
    flex-basis: 60%;
    padding: 1.5rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.aboutHeading{
    font-weight: 900;
    -webkit-text-stroke: 0.1vw #FF9B42;
    font-size: 3rem;
    margin: 0;
    color: #283148;
    line-height: 1.2;
}
.aboutText{
    color: #283148;
    /* font-size: 1rem; */
    font-size: 1.35rem;
    text-align: justify;
    /* margin-bottom: 1.5rem; */
}
.aboutImageContainer{
    flex-basis: 40%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.aboutImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media (max-width:1115px) {
    .aboutHeading{
        font-size: 2.3rem;
    }
    .aboutText{
        font-size: 1.1rem;
    }
}

@media (max-width:1000px) {
    .aboutHeading{
        font-size: 2rem;
    }
    .aboutText{
        font-size: 0.95rem;
    }
}

@media (max-width:890px) {
    .aboutHeading{
        font-size: 1.8rem;
    }
    .aboutText{
        font-size: 0.9rem;
    }
}

@media (max-width:803px) {
    .aboutHeading{
        font-size: 1.6rem;
    }
}

@media (max-width:599px) {
    .aboutSection{
        flex-direction: column;
    }
}

/* @media (max-width:1115px){
    .aboutImage{
        width: 35rem;
        height: 25rem;
    }
    .aboutHeading{
        font-size: 2.1rem;
        margin-top: 1rem;
    }
}

@media (max-width:1100px){
    .aboutImage{
        width: 27rem;
        height: 25rem;
    }
    .aboutImageContainer{
        flex-basis: 40%;
    }
    .aboutContentContainer{
        flex-basis: 60%;
    }
}

@media (max-width:972px){
    .aboutSection{
        height: 55vh;
    }
    .aboutImageContainer{
        flex-basis: 30%;
    }
    .aboutContentContainer{
        flex-basis: 70%;
    }
    .aboutImage{
        width: 23rem;
        height: 21rem;
    }
    .aboutHeading{
        font-size: 2rem;
    }
    .aboutText{
        font-size: 0.8rem;
    }
}

@media (max-width:887px){
    .aboutHeading{
        font-size: 1.8rem;
    }
}

@media (max-width:846px){
    .aboutHeading{
        font-size: 1.6rem;
    }
    .aboutText{
        font-size: 0.75rem;
    }
}

@media (max-width:806px){
    .aboutSection{
        height: 50vh;
    }
    .aboutImage{
        width: 20rem;
        height: 19rem;
    }
    .aboutHeading{
        font-size: 1.4rem;
    }
    .aboutText{
        font-size: 0.7rem;
    }
}

@media (max-width:717px){
    .aboutImage{
        width: 18rem;
        height: 19rem;
    }
    .aboutHeading{
        font-size: 1.3rem;
    }
}

@media (max-width:665px){
    .aboutImage{
        width: 15rem;
        height: 19rem;
    }
}

@media (max-width:640px){
    .aboutSection{
        height: 43vh;
    }
    .aboutContentContainer{
        padding: 1rem;
    }
    .aboutImage{
        width: 15rem;
        height: 16.3rem;
    }
    .aboutHeading{
        font-size: 1.2rem;
        margin-top: 0;
    }
    .aboutText{
        font-size: 0.6rem;
    }
}

@media (max-width:555px){
    .aboutSection{
        flex-direction: column;
        height: 85vh;
    }
    .aboutImage{
        width: 34rem;
        height: 16.3rem;
    }
    .aboutHeading{
        font-size: 1.8rem;
        margin-top: 0;
    }
    .aboutText{
        font-size: 0.7rem;
    }
}

@media (max-width:414px){
    .aboutHeading{
        font-size: 1.6rem;
    }
    .aboutText{
        font-size: 0.7rem;
    }
}

@media (max-width:374px){
    .aboutHeading{
        font-size: 1.4rem;
    }
}

@media (max-width:333px){
    .aboutSection{
        height: 120vh;
    }
    .aboutHeading{
        font-size: 1.1rem;
    }
    .aboutImage{
        height: 22rem;
    }
}

@media (max-width:272px){
    .aboutHeading{
        font-size: 1rem;
    }
}

@media (max-width:252px){
    .aboutHeading{
        font-size: 0.9rem;
    }
} */