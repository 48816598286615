.blog{
    flex: 0 0 32%;
    margin-top: 3rem;
    max-width: 35%;
    height: auto;
    background-color: #FF9B42;
    border-radius: 2rem;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.blogImageContainer{
    position: relative;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blogImageContainer h1{
    position: absolute;
    width: 90%;
    text-align: center;
    margin-left: 1rem;
    color: white;
    font-size: 1.7rem;
}
.blogImage{
    width: 100%;
    height: 100%;
    border-bottom: 0.3rem solid #283148;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}
.blogContent{
    padding: 0 1rem;
    background-color: #FF9B42;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 1.5rem;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
}
.blogContent p{
    width: 100%;
    font-weight: 600;
    color: #283148;
    font-size: 1.35rem;
    bottom: 0;
}
.blogReadBtn{
    border: 0.2rem solid #283148;
    background-color: transparent;
    font-size: 1.35rem;
    padding: 0.7rem;
    border-radius: 0.7rem;
    color: #283148;
    font-weight: 600;
    width: max-content;
}
.blogReadBtn:hover{
    cursor: pointer;
}
.blogBtn{
    position: absolute;
    right: 4rem;
    display: flex;
    align-items: center;
    top: 4rem;
    gap: 0;
    transition: 0.5s;
    color: white;
}
.blogBtnText{
    border: none;
    background-color: #283148;
    font-size: 16px;
    font-weight: 900;
    color: white;
}
.blogBtnText:hover{
    cursor: pointer;
    font-size: 18px;
}

@media (max-width:1115px) {
    .blogContent p,
    .blogReadBtn{
        font-size: 1.1rem;
    }
    .blogImageContainer h1{
        font-size: 1.4rem;
    }
}

@media (max-width:1000px) {
    .blogContent p,
    .blogReadBtn{
        font-size: 0.95rem;
    }
    .blogImageContainer{
        height: 13rem;
    }
    .blogImageContainer h1{
        font-size: 1.2rem;
    }
}

@media (max-width:890px) {
    .testimonial p,
    .blogReadBtn{
        font-size: 0.9rem;
    }
    .blogImageContainer h1{
        font-size: 1.1rem;
    }
}

 @media (max-width:880px) {
    .blogImageContainer{
        height: 11rem;
    }
    .blog {
        flex: 0 0 48%;
        max-width: 50%;
    }
}

@media (max-width:650px) {
    .blog {
        flex: 0 0 98%;
        max-width: 98%;
    }
}

