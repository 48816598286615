.testimonialsSection{
    padding: 4rem 0.5rem;
    background-color: #283148;
    margin-top: 3rem;
    width: 100vw;
    height: auto;
    position: relative;
    box-sizing: border-box;
}
.testimonialsHeading{
    font-weight: 900;
    font-size: 3rem;
    margin: 0;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 3rem;
    color: #FF9B42;
}
.testimonialContainerFlex{
    display: flex;
    align-items: center;
    width: 100%;
}
.testimonialsContainer{
    display: flex;
    gap: 1.35%;
    width: 80vw;
    margin: auto;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
}
.leftArrow{
    background-color: #283148;
    border: none;
    cursor: pointer;
}

.rightArrow{
    background-color: #283148;
    border: none;
    cursor: pointer;
}

@media (max-width:1118px) {
    .testimonialsContainer{
        width: 90vw;
    }
}

@media (max-width:1115px) {
    .testimonialsHeading{
        font-size: 2.3rem;
    }
}

@media (max-width:1000px) {
    .testimonialsHeading{
        font-size: 2rem;
    }
}

@media (max-width:890px) {
    .testimonialsHeading{
        font-size: 1.8rem;
    }
}

@media (max-width:803px) {
    .testimonialsHeading{
        font-size: 1.6rem;
    }
}

@media (max-width:880px) {
    .testimonialsContainer {
        width: 80vw;
        gap: 2.2%;
    }
}

@media (max-width:650px) {
    .testimonialsContainer {
        width: 90vw;
        gap:2.1%;
    }
}

