.contactUs{
    background-color: #283148;
    height: auto;
    padding: 2rem;
    display: flex;
    align-items: center;
}
.contactUsImageContainer{
    flex-basis: 50%;
}
.contactUsImage{
    width: 40vw;
}
.contactUsContentContainer h1{
    font-weight: 900;
    font-size: 3rem;
    margin: 0;
    line-height: 1.2;
    font-family: "Poppins", sans-serif;
    color: #FF9B42;
}
.contactUsContentContainer p{
    color: white;
    width: 80%;
    font-size: 1.35rem;
    margin-bottom: 2rem;
    text-align: justify;
}
.socialMediaIcons{
    display: flex;
    gap: 0.5rem;
}
.socialMediaIcons .subIconBtn{
    border-radius: 0.3rem;
    border: none;
    width: 3.5rem;
    height: 3.5rem;
    background-color: #FF9B42;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.socialMediaIcons .subIconBtn:hover{
    cursor: pointer;
    background-color: transparent;
    border: 0.1rem solid #FF9B42;
}
.socialMediaIcons .subIconBtn svg{
    color: #283148;
    transition: 0.5s;
    font-size: 4rem;
}
.socialMediaIcons .subIconBtn:hover svg{
    color: #FF9B42;
}

@media (max-width:1115px) {
    .contactUsContentContainer h1{
        font-size: 2.3rem;
    }
    .contactUsContentContainer p{
        font-size: 1.1rem;
    }
    .socialMediaIcons .subIconBtn{
        width: 3rem;
        height: 3rem;
    }
    .socialMediaIcons .subIconBtn svg{
        font-size: 3rem;
    }
}

@media (max-width:1000px) {
    .contactUsContentContainer h1{
        font-size: 2rem;
    }
    .contactUsContentContainer p{
        font-size: 0.95rem;
    }
}

@media (max-width:890px) {
    .contactUsContentContainer h1{
        font-size: 1.8rem;
    }
    .contactUsContentContainer p{
        font-size: 0.9rem;
    }
}

@media (max-width:803px) {
    .contactUsContentContainer h1{
        font-size: 1.6rem;
    }
}

@media (max-width:780px) {
    .contactUs{
        flex-direction: column;
    }
    .contactUsContentContainer p{
        width: 100%;
    }
    .contactUsImage{
        width: 80vw;
        height: 50vh;
    }
}

