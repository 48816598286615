.successStoriesSection{
    padding: 4rem 0.5rem;
    position: relative;
    width: 100vw;
    margin-top: 3rem;
    height: auto;
    box-sizing: border-box;
}
.successStoriesHeading{
    font-weight: 900;
    font-size: 3rem;
    margin: 0;
    line-height: 1.2;
    text-align: center;
    color: #283148;
}
.successStoriesContainerFlex{
    display: flex;
    align-items: center;
    width: 100%;
}
.successStories{
    display: flex;
    gap: 1.35%;
    width: 80vw;
    margin: auto;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
    opacity: 0;
    transition: opacity 0.5s ease;
}
.successStories.visible{
    opacity: 1;
}
.leftArrow{
    background-color: white;
    border: none;
    cursor: pointer;
}
.rightArrow{
    background-color: white;
    border: none;
    cursor: pointer;
}
.leftArrow svg,
.rightArrow svg{
    color: #283148;
}

@media (max-width:1118px) {
    .successStories{
        width: 90vw;
    }
}

@media (max-width:1115px) {
    .successStoriesHeading{
        font-size: 2.3rem;
    }
}

@media (max-width:1000px) {

    .successStoriesHeading{
        font-size: 2rem;
    }
}

@media (max-width:890px) {
    .successStoriesHeading{
        font-size: 1.8rem;
    }
}

@media (max-width:803px) {
    .successStoriesHeading{
        font-size: 1.6rem;
    }
}

@media (max-width:880px) {
    .successStories{
        width: 80vw;
        gap: 2.3%;
    }
}

@media (max-width:650px) {
    .successStories{
        gap: 2.1%;
    }
}



