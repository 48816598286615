.footerSection{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: white;
    margin-top: 3rem;
}
.mainFooterSection{
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #283148;
    justify-content: center;
    padding: 2rem;
}
.footerFirstSection{
    display: flex;
    flex-direction: column;
    gap: 5rem;
}
.footertwoSectionsContainer{
    display: flex;
    gap: 5rem;
}

.footerContentContainer{
    display: flex;
    width: 90%;
    margin-top: 3rem;
    align-items: center;
    justify-content: center;
    flex-basis: 40%;
    background-color: white;
    padding: 3rem;
    border-radius: 2rem;
}
.footerContentContainer > p{
    color: #283148;
    width: 90%;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
}
.footerLogo{
    width: 10rem;
}
.footerQuickLinksContainer{
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}
.footerQuickLinksContainer > h2{
    font-weight: 900;
    margin: 0;
    font-size: 2rem;
    color: white;
    margin-bottom: 1rem;
    font-family: "Poppins", sans-serif;
}
.links{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}
.address,
.mail,
.phone{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #FF9B42;
    width: 100%;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    margin-bottom: 0.5rem;
}
.address svg,
.mail svg,
.phone svg{
    color: #283148;
}
.address > p,
.mail > p{
    color: #283148;
    width: 90%;
    font-size: 1.15rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
}
.phone p{
    color: #283148;
    font-size: 1.15rem;
    font-weight: 600;
}

.footerMapContainer{
    width: 70%;
    padding: 2rem;
    margin: auto;
    overflow: hidden;
}
  
.mapImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
} 


/*-------------------------------------------Mini Footer Section------------------------------------------------------*/

.miniFooterSection{
    height: 13vh;
    background-color: #FF9B42;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.termsCondition,
.copyright{
    display: flex;
    gap: 0.5rem;
}
.termsCondition a,
.copyright a{
    font-size: 1.35rem;
    font-weight: 900;
    color: #283148;
}
.termsCondition a:hover,
.copyright a:hover{
    cursor: pointer;
    color: black;
}

@media (max-width:1115px) {
    .footerQuickLinksContainer > h2{
        font-size: 1.4rem;
    }
    .termsCondition a,
    .copyright a,
    .footerContentContainer > p,
    .mail p,
    .address p,
    .phone p{
        font-size: 0.9rem;
    }
}

@media (max-width:1000px) {
    .footerQuickLinksContainer > h2{
        font-size: 1.2rem;
    }
    .termsCondition a,
    .copyright a,
    .footerContentContainer > p,
    .mail p,
    .address p,
    .phone p{
        font-size: 0.85rem;
    }
}

@media (max-width:890px) {
    .footerQuickLinksContainer > h2{
        font-size: 1.1rem;
    }
    .termsCondition a,
    .copyright a,
    .footerContentContainer > p,
    .mail p,
    .address p,
    .phone p{
        font-size: 0.8rem;
    }
}

@media (max-width:850px) {
    .footertwoSectionsContainer{
        gap: 2rem;
    }
}

@media (max-width:800px) {
    .footerFirstSection{
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media (max-width:790px) {
    .footertwoSectionsContainer{
        gap: 1rem;
    }
}

@media (max-width:770px) {
    .footerFirstSection{
        gap: 1rem;
    }
    .footertwoSectionsContainer{
        flex-direction: column;
    }
    .footerMapContainer{
        width: 100%;
    }
}

@media (max-width:430px) {
    .links{
        margin-left: -3rem;
        width: 110%;
    }
}
