.servicesContainer{
    padding: 2rem;
    padding-bottom: 5rem;
    width: 100vw;
    margin-top: 2rem;
}
.servicesContainer > h1{
    font-weight: 900;
    text-align: center;
    font-size: 3rem;
    margin-bottom: 3rem;
    margin-top: 0;
    color: #283148;
    line-height: 1.2;
    word-spacing: 4px;
}
.servicesText{
    font-size: 1.35rem;
    width: 90%;
    margin: auto;
    margin-bottom: 3rem;
    text-align: center;
}
.services{
    display: flex;
    justify-content: space-evenly;
    gap: 3rem;
    width: 90%;
    margin: auto;
    opacity: 0;
    transform: translateX(-200px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}
.services.visible{
    opacity: 1;
    transform: translateX(0);
}
.service{
    background-color: #283148;
    padding: 1rem 2rem;
    width: 40rem;
    border-radius: 1rem;
    color: white;
}
.service > h2{
    font-weight: 600;
}

@media (max-width:1115px) {
    .servicesContainer > h1{
        font-size: 2.3rem;
    }
    .servicesText{
        font-size: 1.1rem;
    }
    .service{
        font-size: 0.rem;
    }
}

@media (max-width:1000px) {
    .servicesContainer > h1{
        font-size: 2rem;
    }
    .servicesText{
        font-size: 0.95rem;
    }
}

@media (max-width:890px) {
    .servicesContainer > h1{
        font-size: 1.8rem;
    }
    .servicesText{
        font-size: 0.9rem;
    }
    .service > h2{
        font-size: 0.9rem;
    }
}

@media (max-width:803px) {
    .servicesContainer > h1{
        font-size: 1.6rem;
    }
}

@media (max-width:750px) {
    .service > h2{
        font-size: 0.8rem;
    }
}

@media (max-width:710px){
    .servicesContainer{
        flex-direction: column;
    }
    .services{
        display: grid;
        grid-template-columns: 1fr;
    }
    .service{
        width: 100%;
    }
}

@media (max-width:395px){
    .services{
        transform: translateX(-100px);
    }
}

