.testimonial {
    flex: 0 0 32%;
    max-width: 35%;
    height: auto;
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.testimonial:hover{
    background-color: transparent;
    border: 0.1rem solid #FF9B42;
}
.testimonial p{
    font-size: 1.3rem;
    color: #283148;
    font-weight: 600;
}
.testimonial:hover p{
    color: #fff;
}
.user{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    height: 5rem;
    border: 1px solid #283148;
    padding-left: 0.5rem;
    border-radius: 1rem;
}
.testimonial:hover .user{
    background-color: transparent;
    border: 0.1rem solid #FF9B42;
}
.avatar{
    background-color: #283148;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.testimonial:hover .avatar{
    border: 0.1rem solid #FF9B42;
}
.avatar h1{
    font-size: 2rem;
}
.testimonial:hover .avatar h1{
    color: white;
}
.userInfo{
    display: flex;
    flex-direction: column;
}
.name{
    margin-top: 0.5rem;
    margin-bottom: -0.8rem;
    font-size: 1.3rem;
}
.status{
    font-size: 1rem;
}
.testimonial:hover .name, 
.testimonial:hover .status {
    color: #fff;
}
.icon{
    font-size: 55px !important;
    color: white;
}


@media (max-width:1115px) {
    .testimonial p,
    .name{
        font-size: 1.1rem;
    }
    .status{
        font-size: 0.9rem;
    }
    .avatar h1{
        font-size: 1.4rem;
    }
}

@media (max-width:1000px) {
    .testimonial p,
    .name{
        font-size: 0.95rem;
    }
    .status{
        font-size: 0.75rem;
    }
    .avatar{
        width: 3rem;
        height: 3rem;
    }
    .avatar h1{
        font-size: 1.2rem;
    }
}

@media (max-width:890px) {
    .testimonial p,
    .name{
        font-size: 0.9rem;
    }
    .status{
        font-size: 0.7rem;
    }
    .avatar h1{
        font-size: 1.1rem;
    }
}

@media (max-width:880px) {
    .testimonial {
        flex: 0 0 48%;
        max-width: 50%;
    }
}

@media (max-width:650px) {
    .testimonial {
        flex: 0 0 98%;
        max-width: 98%;
    }
}

