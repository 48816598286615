.fAQSection{
    background-color: white;
    padding: 2rem;
    height: auto;
}
.fAQSection h1{
    font-weight: 900;
    font-size: 3rem;
    margin: 0;
    line-height: 1.2;
    color: #283148;
    text-align: center;
    margin-bottom: 3rem;
}
.fAQContainer{
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin: auto;
}

@media (max-width:1115px) {
    .fAQSection h1{
        font-size: 2.3rem;
    }
}

@media (max-width:1000px) {
    .fAQSection h1{
        font-size: 2rem;
    }
}

@media (max-width:890px) {
    .fAQSection h1{
        font-size: 1.8rem;
    }
}

@media (max-width:803px) {
    .fAQSection h1{
        font-size: 1.6rem;
    }
}

